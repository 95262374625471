import {
    BalCard,
    BalCardContent,
    BalHeading,
    BalText,
} from "@baloise/ds-react";
import {
    Form,
    FormProps,
    FormValues,
} from "@baloise-cfa/form-renderer-frontend";
import {
    PaymentMethodFrequency,
    PaymentMethodType,
} from "@baloise-cfa/tsclient/mob";
import { useMediaQuery } from "@lg-cfa/hooks";
import classNames from "classnames";
import { graphql, navigate } from "gatsby";
import React, { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";

import {
    paymentTermsFormConfig,
    PriceCard,
    QuoteFormKey,
    QuoteFormState,
    quoteFormState,
    QuoteLayout,
} from "@modules/quote";
import { Footer, Navigation } from "@modules/shared/components";
import {
    calculateTotalInsurancePrice,
    getNavigationPages,
} from "@modules/shared/helpers";
import { appState, AppState } from "@modules/shared/state";
import { PageProps } from "@modules/shared/types";

const PaymentTerms: React.FC<PageProps> = ({ pageContext }) => {
    const { foundPage: page, language, ownPageObjects: allPages } = pageContext;
    const [formState, setFormState] = useRecoilState(quoteFormState);
    const formRef = useRef<FormProps<FormValues>>();
    const appData = useRecoilValue<AppState>(appState);
    const { t } = useTranslation();
    const { nextPage, prevPage } = getNavigationPages(allPages, page);
    const isMobile = useMediaQuery("(max-width: 768px)");

    useEffect(() => {
        if (!appData?.InsuranceChoice?.insurance?.type) {
            // Navigate to the product page when there is no insurance choice selected
            // A quote flow can only started when there is a simulation available
            navigate("/");
        }
    }, [appData]);

    const initialFormValues: QuoteFormState = useMemo(() => {
        return {
            ...formState,
            paymentTerms: formState?.paymentTerms,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = (values: FormValues): void => {
        updateFormState(values);

        if (nextPage) {
            navigate(nextPage.paths[language]);
        }
    };

    const handleOnChange = (values: FormValues): void => {
        updateFormState(values);
    };

    const updateFormState = (formValues: QuoteFormState): void => {
        setFormState({
            ...formValues,
            paymentTerms: formValues?.paymentTerms,
        });
    };

    const paymentPeriod = useMemo(() => {
        if (
            formState?.paymentTerms?.paymentType === PaymentMethodType.Transfer
        ) {
            return PaymentMethodFrequency.Yearly;
        }
        return formState?.paymentTerms?.period;
    }, [formState?.paymentTerms?.paymentType, formState?.paymentTerms?.period]);

    return (
        <QuoteLayout
            title={t("quote.paymentterms.title")}
            page={page}
            allPages={allPages}
            language={language}
        >
            <main className="container mt-large is-compact flex flex-direction-column">
                <div className="flex-1">
                    <BalHeading level="h1" subtitle>
                        {t("quote.paymentterms.title")}
                    </BalHeading>
                    <BalText>{t("quote.paymentterms.intro")}</BalText>
                    <div
                        className={classNames("flex gap-large", {
                            "flex-direction-column": isMobile,
                        })}
                    >
                        <BalCard>
                            <BalCardContent>
                                <Form
                                    ref={formRef}
                                    scrollToFieldError
                                    errorMessageCardTitle={
                                        t("all.errormessage.title") as string
                                    }
                                    errorMessageCardSubTitle={
                                        t("all.errormessage.text") as string
                                    }
                                    formContext={{
                                        t,
                                        nameSpace: QuoteFormKey.PaymentTerms,
                                        fieldWrapper: {
                                            optionalLabel: "optional",
                                        },
                                    }}
                                    initialValues={initialFormValues}
                                    onSubmit={handleSubmit}
                                    onChange={handleOnChange}
                                    fields={paymentTermsFormConfig.fields}
                                />
                            </BalCardContent>
                        </BalCard>

                        <PriceCard
                            yearlyPrice={calculateTotalInsurancePrice(
                                formState?.insurances?.insurance,
                            )}
                            period={paymentPeriod}
                        />
                    </div>
                    <div className="mt-large">
                        <Navigation
                            t={t}
                            language={language}
                            prevPage={prevPage}
                            nextPage={nextPage}
                            onNextClick={() => formRef.current?.handleSubmit()}
                        />
                    </div>
                </div>
                <Footer />
            </main>
        </QuoteLayout>
    );
};

export default PaymentTerms;

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
